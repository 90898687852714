var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"outlined":""}},[_c('v-card-title',[_c('v-row',{staticClass:"py-1 grey lighten-3",attrs:{"align":"center","no-gutters":""}},[_c('h5',{staticClass:"mx-3 grey--text text--darken-2 font-weight-medium"},[_vm._v(_vm._s(_vm.lang.companies))]),_c('v-spacer'),(false)?_c('v-btn',{staticClass:"mx-3",attrs:{"color":"success","small":"","depressed":""}},[_vm._v(_vm._s(_vm.lang.exportToCsv))]):_vm._e()],1)],1),_c('v-card-text',[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{directives:[{name:"debounce",rawName:"v-debounce:200ms",value:(_vm.watchOptions),expression:"watchOptions",arg:"200ms"}],staticClass:"rounded-0",attrs:{"clearable":"","hide-details":"auto","outlined":"","dense":"","append-icon":"mdi-magnify","label":_vm.lang.search},on:{"click:append-outer":_vm.watchOptions,"click:append":_vm.watchOptions},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.companies,"hide-default-footer":true,"loading":_vm.loading,"items-per-page":_vm.itemsPerPage,"show-select":false,"page":_vm.page,"loading-text":_vm.lang.loading,"no-results-text":_vm.lang.noResults,"no-data-text":_vm.lang.noResults,"search":_vm.search,"options":_vm.options,"server-items-length":_vm.totalItems,"disable-sort":"","disable-filtering":""},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"click:row":_vm.rowClick,"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"header.connectorId",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(_vm._s(header.text))])]}},{key:"header.commercialName",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(_vm._s(header.text))])]}},{key:"header.name",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(_vm._s(header.text))])]}},{key:"header.cif",fn:function(ref){
var header = ref.header;
return [_c('h3',{staticClass:"grey lighten-2 py-2 font-weight-medium full-height d-flex justify-center align-center"},[_vm._v(_vm._s(header.text))])]}},{key:"footer",fn:function(){return [_c('v-pagination',{staticClass:"custom-pagination",attrs:{"length":_vm.pageCount,"total-visible":8},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})]},proxy:true}])})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }